import { template as template_a43ab1475a834bf7b21c9b7248b02f9a } from "@ember/template-compiler";
const FKControlMenuContainer = template_a43ab1475a834bf7b21c9b7248b02f9a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
