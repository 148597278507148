import { template as template_845e6c840de2496d86b56d99aff3b1d3 } from "@ember/template-compiler";
const FKText = template_845e6c840de2496d86b56d99aff3b1d3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
