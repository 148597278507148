import { template as template_e48d0bf9b3ea46009b7a7d7db5045af5 } from "@ember/template-compiler";
const WelcomeHeader = template_e48d0bf9b3ea46009b7a7d7db5045af5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
